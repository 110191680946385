<script>
  import GitHubIcon from '../icons/github.svg'
  import LinkedInIcon from '../icons/linkedin.svg'
  import MailIcon from '../icons/mail.svg'
  import MOIAIcon from '../icons/moia.svg'
  import StackOverflowIcon from '../icons/stack-overflow.svg'
  import FooterLink from './FooterLink.svelte'
  import GlobalStyles from './GlobalStyles.svelte'
  import Projects from './Projects.svelte'

</script>

<GlobalStyles />
<main class="flex flex-col justify-center bg-white px-4 sm:px-8 pt-10 pb-4">
  <div class="flex flex-col justify-center max-w-2xl mx-auto">
    <div class="flex">
      <div class="flex flex-col mr-4 flex-grow">
        <h1
          class="font-bold text-3xl md:text-4xl tracking-tight mb-4 text-black">
          Hey, I'm Jonathan
        </h1>
        <h2 class="text-gray-600 flex items-center flex-wrap">
          I'm a software developer working at
          <a
            href="https://www.moia.io/"
            target="_blank"
            class="text-moia-primary hover:text-moia-secondary transition-colors mx-2 mb-0.5">
            <MOIAIcon style="height: 16px;" />
          </a>
          in Hamburg.
        </h2>
      </div>
      <img
        class="w-32 h-32 object-cover rounded shadow hidden sm:block"
        src="/me.jpeg"
        alt="Jonathan Wieben" />
    </div>
    <Projects />
  </div>
</main>
<footer class="flex flex-col items-center mb-4 sm:mb-6">
  <div class="flex space-x-4 mb-4">
    <FooterLink href="https://github.com/JonathanWbn">
      <GitHubIcon class="h-5 w-5" />
    </FooterLink>
    <FooterLink href="https://stackoverflow.com/users/7879109/jonathan-wieben">
      <StackOverflowIcon class="h-5 w-5" />
    </FooterLink>
    <FooterLink href="https://www.linkedin.com/in/jonathan-wieben-068314123">
      <LinkedInIcon class="h-5 w-5" />
    </FooterLink>
    <FooterLink href="mailto:jwieben@hey.com">
      <MailIcon class="h-5 w-5" />
    </FooterLink>
  </div>
  <div class="space-x-3">
    <!-- <FooterLink href="/resume.pdf">/resume</FooterLink> -->
    <FooterLink
      external={false}
      href="https://jwieben.notion.site/Impressum-7be1b0e1a1384c1cb9362bd1aef963d1">
      /impressum
    </FooterLink>
  </div>
</footer>

<script>
  import AWSIcon from '../icons/aws.svg'
  import BriefcaseIcon from '../icons/briefcase.svg'
  import LinkIcon from '../icons/link.svg'
  import PenIcon from '../icons/pen.svg'
  import SoccerIcon from '../icons/soccer.svg'
  import UsersIcon from '../icons/users.svg'
  import Project from './Project.svelte'

</script>

<h3 class="font-bold text-2xl md:text-3xl tracking-tight mb-4 mt-10 text-black">
  Projects
</h3>
<Project
  name="Team Generator"
  url="https://teamgenerator.io"
  description="Split your team in groups">
  <UsersIcon class="h-6 w-6 sm:h-8 sm:w-8 min-w-sm text-gray-900" />
</Project>
<Project
  name="AWS Raycast Extension"
  url="https://www.raycast.com/Falcon/aws"
  description="Supercharged AWS productivity">
  <AWSIcon class="h-6 w-6 sm:h-8 sm:w-8 min-w-sm text-gray-900" />
</Project>
<Project
  name="Thanks For Playing"
  url="https://github.com/JonathanWbn/kicker-tracker"
  description="Game tracker with Elo rating">
  <SoccerIcon class="h-6 w-6 sm:h-8 sm:w-8 min-w-sm text-gray-900" />
</Project>
<Project
  name="Notion Tools"
  url="https://notion-tools.io/"
  description="Notion extensions and automations"
  shortDescription="A set of tools to automate stuff in Notion">
  <BriefcaseIcon class="h-6 w-6 sm:h-8 sm:w-8 min-w-sm text-gray-900" />
</Project>
<Project
  name="Meeting Notes Live"
  url="https://live-notes.vercel.app/"
  description="Real-time markdown note sharing"
  shortDescription="">
  <PenIcon class="h-6 w-6 sm:h-8 sm:w-8 min-w-sm text-gray-900" />
</Project>
<Project
  name="Schort"
  url="https://schort.me/"
  description="Just another URL shortener">
  <LinkIcon class="h-6 w-6 sm:h-8 sm:w-8 min-w-sm text-gray-900" />
</Project>

<script>
  export let href
  export let external = true

</script>

<a
  class="text-sm text-gray-500 hover:text-gray-600 transition"
  target={external ? '_blank' : ''}
  rel="noopener noreferrer"
  {href}>
  <slot />
</a>

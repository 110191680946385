<script>
  export let name
  export let description
  export let url

</script>

<a
  class="mb-4 hover:shadow transition-shadow w-full rounded"
  href={url}
  target="_blank"
  rel="noopener noreferrer">
  <div class="flex items-center border border-gray-200 rounded p-2 sm:p-4">
    <div class="h-6 w-6 sm:h-8 sm:w-8 ml-2 mr-4">
      <slot />
    </div>
    <div>
      <h4 class="text-lg font-bold tracking-tight text-gray-900 no-underline">
        {name}
      </h4>
      <p class="leading-5 text-gray-700 no-underline">{description}</p>
    </div>
  </div>
</a>
